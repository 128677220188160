import * as React from 'react';
import Moment from 'moment';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as Api from '../api/api';
import * as WorkDocumentsStore from '../store/WorkDocuments';
import * as CriteriaStore from '../store/Criteria';
import * as DocumentsStore from '../store/Document';
import { ApplicationState } from '../store';
import Loader from './Loader';
import LocationSelect from './LocationSelect';
import Select, { Option } from "./Select";
import * as Colors from "../styles/colors";
import { getText,  translateEnum } from '../utils/langTexts';
import Tooltip from './Tooltip';
import Button from './Button';
import Table, { Column } from './Table';
import { searchContainerStyle, searchFieldStyle, labelStyle, inputStyleModerator, formContainerStyle, filterForm, buttonStyle } from "../styles/moderatorStyles";
import CenteredContentTabs from './CenteredContentTabs';
import { CreateModeratorWorkdocumentsTemplateForm, CreateModeratorWorkdocumentsUploadForm } from './ModeratorWokrdocumentsUploadForm';

import { CreateModeratorWokrdocumentsUploadEntitiesForm } from './ModeratorSearchUploadEntitiesForm';
import CheckBox from './CheckBox';





type ModeratorWorkDocumetsUploadProps = ModeratorWorkDocumetsUploadOwnProps
    & WorkDocumentsStore.WorkDocumentsState 
    & typeof WorkDocumentsStore.actionCreators
    & typeof DocumentsStore.actionCreators
    ;

interface ModeratorWorkDocumetsUploadOwnProps {
    carriers: { [id: number]: Api.CarrierModel }
    subscription: Api.SubscriptionModel;
}

const getColumns = (props: ModeratorWorkDocumetsUploadProps): Array<Column<Api.WorkDocumentsClientUploadDisplayModel>> => {
    return [
        {
            header: getText("MdtRatesDocumentsCarrier"),
            id: 'carrier',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => ( props.carriers[d.carrierId].name),
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsReference"),
            id: 'reference',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d.reference,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsTrafficType"),
            id: 'trafficType',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d.trafficType,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsValidityStart"),
            id: 'validityStart',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d.validityBegin,
            cell: (d: any) => <div>
                {d && Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsValidityEnd"),
            id: 'validityEnd',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d.validityEnd,
            cell: (d: any) => <div>
                {d && Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsReception"),
            id: 'reception',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d.creationDate,
            cell: (d: any) => <div>
                {d && Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: "State",
            id: 'state',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d.state,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("CrtContainerType"),
            id: 'containerType',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d.containerType,
            cell: (d: any) => <div>
                {translateEnum(d, "EnumChargeSetModelContainerTypeEnum")}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsCompletion"),
            id: 'completion',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d.doneDate,
            cell: (d: any) => <div>
                {d && Moment(d).format("DD/MM/YYYY")}
            </div>
        },
        {
            header: "Integrated by",
            id: 'owner',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d.integratedBy,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: getText("MdtRatesDocumentsInformation"),
            id: 'information',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d.additionalInfo,
            cell: (d: any) => <div>
                {d}
            </div>
        },
        {
            header: '',
            id: 'action',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d,
            cell: (d: any) => {
                let workDocuments = d as Api.WorkDocumentsClientUploadDisplayModel;
                return (
                    workDocuments.workdocumentId >0 &&
                    <div style={{ position: "relative" }}>
                        <span
                            onClick={(e) => {
                                props.requestDeactivateUploadedWorkdocument(new Date().getTime(), workDocuments.workdocumentId, true);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            style={{
                                cursor: "pointer",
                                ...(workDocuments.invalidated == false ? Colors.colors.main3 : {})
                            }}>
                                {getText("GenEnabled")}
                        </span>
                        <span> | </span>
                        <span
                            onClick={(e) => {
                                props.requestDeactivateUploadedWorkdocument(new Date().getTime(), workDocuments.workdocumentId, false);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            style={{
                                cursor: "pointer",
                                ...(workDocuments.invalidated == true ? Colors.colors.main3 : {})
                            }}>
                                {getText("GenDisabled")}
                        </span>


                    </div>
                );
            }
        },
        {
            header: '',
            id: 'download',
            accessor: (d: Api.WorkDocumentsClientUploadDisplayModel) => d,
            cell: (d: any) => {
                let workDocuments = d as Api.WorkDocumentsClientUploadDisplayModel;
                return (
                    workDocuments.workdocumentId > 0 &&
                    <div style={{ position: "relative" }}>
                        <span
                            onClick={(e) => {
                                props.requestDownloadWorkdocumentDocument(new Date().getTime(), workDocuments.workdocumentId);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            style={{
                                cursor: "pointer",
                            }}>
                            {getText("GenDownload")}
                        </span>
                    </div>
                );
            }
        }
    ];
}





const OriginDestSize = { width: "15em" };

const trafficTypeOptions: Array<{ label: string; value: Api.WorkDocumentsModelTrafficTypeEnum }> = [
    { label: "------", value: null },
    { label: "Export", value: "Export" },
    { label: "Import", value: "Import" },
    { label: "Cross trade", value: "CrossTrade" }
];

class ModeratorWorkDocumetsUpload extends React.Component<ModeratorWorkDocumetsUploadProps, {}> {

    get carriersOptions(): Array<Option> {
        return _.sortBy(_.values(this.props.carriers), x => x.name)
            .map(ca => ({ label: ca.name, value: ca.carrierId }));
    }

    get selectedWorkDocuments(): Api.WorkDocumentsModel {
        return this.props.workDocumentses
            .find(x => x.workDocumentsId === this.props.editState.workDocumentsId);
    }


    componentWillMount() {
        this.props.selectEnabledState(true);
        this.props.requestSeedChargeNames(new Date().getTime());
    }

    public render() {

        return (
            <div>
                <h2>{getText("MdtPricelistName")}</h2>
                <div style={searchContainerStyle}>
                    <form className="form-inline" style={filterForm}
                        onSubmit={(e) => {
                            this.props.requestUploadWorkDocumentses(new Date().getTime());
                            e.preventDefault();
                        }}>


                        <div className="form-group" style={searchFieldStyle}>
                            <label className="control-label">
                                {getText("MdtRatesDocumentsFilterCarrier")}
                            </label>
                            <Select
                                options={this.carriersOptions}
                                value={this.props.searchWorkDocumentsModel.carrierId}
                                onChange={(value) => this.props.selectCarrierId(value)}
                                style={{ width: "7em", padding: "4px 4px 4px 8px" }}
                                search={true}
                            />
                        </div>
                        <div className="form-group" style={searchFieldStyle}>
                            <label className="control-label" style={{ ...labelStyle }}>{getText("MdtRatesDocumentsFilterOrigin")}</label>
                            <div>
                                <LocationSelect
                                    className="form-control"
                                    inputKey={"WORKDOCUMENTS_ORIGIN"}
                                    value={this.props.searchWorkDocumentsModel.origin}
                                    onChange={(location) => this.props.selectLocation(location, CriteriaStore.Source.Origin)}
                                />
                            </div>
                        </div>
                        <div className="form-group" style={searchFieldStyle}>
                            <label className="control-label" style={{ ...labelStyle }}>{getText("MdtRatesDocumentsFilterDestination")}</label>
                            <div>
                                <LocationSelect
                                    className="form-control"
                                    inputKey={"WORKDOCUMENTS_DESTINATION"}
                                    value={this.props.searchWorkDocumentsModel.destination}
                                    onChange={(location) => this.props.selectLocation(location, CriteriaStore.Source.Destination)}
                                    style={OriginDestSize}
                                />
                            </div>
                        </div>
                        <div className="form-group" style={searchFieldStyle} >
                            <label className="control-label" style={{ paddingRight: "8px" }}>{getText("MdtRatesDocumentsFilterTrafficType")}</label>
                            <Select
                                options={trafficTypeOptions}
                                value={this.props.searchWorkDocumentsModel.trafficType}
                                onChange={(value) => this.props.selectTrafficType(value)}
                                style={{ width: "5em", padding: "4px 4px 4px 8px" }}
                            />
                        </div>
                        <div className="form-group" style={searchFieldStyle} >         
                            <CheckBox label={"Only enabled"} value={this.props.searchWorkDocumentsModel.onlyEnabled} 
                                onChange={(value) => { this.props.selectEnabledState(value); } }  />                     
                        </div>
                        <div className="form-group" style={searchFieldStyle}>
                            <label className="control-label" style={{ ...labelStyle }}>{getText("MdtRatesDocumentsFilterReference")}</label>
                            <div>
                                <input type="text" className="form-control" style={inputStyleModerator}
                                    value={this.props.searchWorkDocumentsModel.reference}
                                />
                            </div>
                        </div>
                        <Button
                            type="submit"
                            style={buttonStyle}>
                            {getText("MdtRatesDocumentsSearch")}
                        </Button>
                        <Loader isVisible={this.props.isLoading} size={100} />

                    </form>
                </div>
                <div className='okargo-table'>
                    <Table
                        tableKey={"mdWorkdocuments"}
                        data={this.props.uploadedWorkDocumentses}
                        columns={getColumns(this.props)}
                        rowsPerPage={8}
                        showPagination={true}
                        isLoading={this.props.isLoading}
                        loadingComponent={() => <Loader isVisible={this.props.isLoading} size={90} />}
                        showPageSizeOptions={false}
                        noDataText={getText("MdtNoRatesInformation")}
                        onBodyTrClick={(workDocuments: Api.WorkDocumentsModel) => {
                            if (workDocuments) {
                                this.props.selectWorkDocuments(workDocuments.workDocumentsId);
                            }
                        }}
                    />
                </div>
                <div>

                    <div style={{ display: "flex", marginLeft: "auto", marginRight: "auto" }}>
                        <div style={{ paddingLeft: 50, display: "flex", flexDirection: "row", flexGrow: 1 }}>
                            <div style={{ flexGrow: 1 }}>
                                <h2>{getText("MdtPricelistDownloadTemplate")}</h2>
                                <div>
                                    <CreateModeratorWorkdocumentsTemplateForm
                                        onSubmit={(values) => { }}
                                        requestTemplateDownload={(values) => {
                                            this.props.requestWorkdocumentTemplate(new Date().getTime(), values);
                                        }}


                                    />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1}}>
                                <div >
                                    <h2>{getText("MdtPricelistTemplate")}</h2>
                                    <CreateModeratorWorkdocumentsUploadForm
                                        onSubmit={(values) => { this.props.requestUploadWorkdocument(new Date().getTime(), values); }}
                                        requestTemplateDownload={() => { }}


                                    />
                                </div>
                                <div >
                                   
                                    <CreateModeratorWokrdocumentsUploadEntitiesForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.workDocuments,
        carriers: state.seed.carriers,
        agencies: state.account.currentUser.clientModel.agencies,
        subscriptions: state.account.currentUser.clientModel.subscriptions,
        firms: state.account.currentUser.clientModel.subscriptions.filter(x => x.subscriptionType === "Okargo")
            .map(x => x.agency.company)
            .concat(state.account.currentUser.clientModel.subscriptions.filter(x => x.subscriptionType === "Okargo")
                .map(x => x.agency.company.companyGroup))
            .concat(state.account.currentUser.clientModel.agencies)
            .filter(x => x),
        subscription: state.account.currentUser.clientModel
            ? _.sortBy(state.account.currentUser.clientModel.subscriptions,
                x => x.subscriptionId === state.account.currentUser.clientModel.subscriptionId)
                .reverse()[0]
            : {}
    } as any),
    {
        ...WorkDocumentsStore.actionCreators,
        ...DocumentsStore.actionCreators
    }
        )(ModeratorWorkDocumetsUpload) as any