import * as React from 'react';
import * as _ from 'lodash';
import Throttle from '../utils/throttleHandler';
import * as Api from '../api/api';
import * as LocationStore from '../store/Location';
import { getLocationName, Guid } from "../utils/utils";
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import InputText from './InputText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper, List } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Colors from "../styles/colors";
import Select from "./Select";

type LocationSelectProps = { locationStates: LocationStore.LocationState }
    & LocationSelectExternalProps
    & LocationSelectOwnProps
    & typeof LocationStore.actionCreators;

interface LocationSelectExternalProps {
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    className?: string;
    disabled?: boolean;
    inputKey: string;
    value: Api.LocationModel;
    locationSearchType?: LocationSearchType;
    defaultRegion?: string;
    onChange: (value: Api.LocationModel) => void;
}

interface LocationSelectOwnProps {
    countries: { [id: number]: Api.LocationModel };
}

interface LocationSelectState {
}

export type LocationSearchType = "Normal"
    | "Inland"
    | "OnlyPorts";

class LocationSelect extends React.Component<LocationSelectProps, LocationSelectState> {
    private guid: string;

    constructor(props: LocationSelectProps) {
        super(props);
        this.guid = Guid.newGuid();
        this.state = {
        };
    }
    handleInputChange(value: string) {
        value = value || "";
        this.props.updateLocationInputText(this.props.inputKey, value);
        Throttle.throttle(this.guid, () => {
            if (value.length >= 3) {
                this.props.requestLocationSearch(this.props.inputKey,
                    this.props.locationSearchType
                    || "Normal",
                    this.region,
                    new Date().getTime());
            }
        }, 300);
    }

    handleChange(location: Api.LocationModel) {
        if (!location) {
            this.props.onChange(null);
        } else {
            this.props.onChange(location);
        }

        this.props.updateLocationInputText(this.props.inputKey, "");
        this.props.locationEmptyOptions(this.props.inputKey);
    };

    getIcon(location: Api.LocationModel): string {
        if (location.locationType === "Port") {
            return location.type === "Dry"
                ? "../images/iconPortLand.png"
                : "../images/iconPortWater.png"
        }

        if (location.locationType === "InlandPlace"
            || location.locationType === "InlandLocationGroup") {
            return "../images/iconInlandPlace.png"
        }

        if (location.locationType === "Terminal") {
            return "../images/iconTerminal.png"
        }

        if(location.locationType === "PortGroup"){
            return "../images/iconPortGroup.png"
        }
        
        return "../images/iconPortWater.png";
    }

    get options(): Array<Api.LocationModel> {
        return this.props.locationStates[this.props.inputKey]
            ? (this.props.locationStates[this.props.inputKey].locations || [])
            : [];
    }

    get region(): string {
        if (this.props.locationStates[this.props.inputKey] && this.props.locationStates[this.props.inputKey].region) {
            return this.props.locationStates[this.props.inputKey].region;
        }

        return this.props.defaultRegion;
    }

    get isPopupOpen(): boolean {
        return this.options.length !== 0;
    }

    get inputValue(): string {
        let inputText = (this.props.locationStates[this.props.inputKey]
            && this.props.locationStates[this.props.inputKey].inputText
            ? this.props.locationStates[this.props.inputKey].inputText
            : "");
        let locationText = (this.props.value ? getLocationName(this.props.value) : "");
        return inputText
            || locationText;
    }

    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                {this.props.locationSearchType === "Inland"
                    && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                        <div style={{ marginRight: 4 }}>
                            Country:
                        </div>
                        <Select options={_.sortBy(_.values(this.props.countries)
                            .map(x => ({ label: x.name, value: x.code })), x => x.label)}
                            search
                            style={{ minWidth: 190 }}
                            inputStyle={{}}
                            value={this.region}
                            onChange={(value) => this.props.locationUpdateRegion(this.props.inputKey, value)} />
                    </div>}
                <div>
                    <Autocomplete
                        style={{
                            width: 250,
                            ...this.props.style
                        }}
                        open={this.isPopupOpen}
                        disabled={this.props.disabled}
                        options={this.options}
                        onBlur={(e) => { this.handleChange(this.props.value) }}
                        filterOptions={x => x}
                        inputValue={this.inputValue}
                        onInputChange={(e, value) => {
                            //Prevent search on select
                            if (e && e.type === "click") {
                                return;
                            }
                            if (!value
                                && this.props.locationStates[this.props.inputKey]
                                && this.props.locationStates[this.props.inputKey].inputText) {
                                //console.log("changed input to null");
                                this.props.onChange(null);
                            }
                            this.handleInputChange(value);
                        }}
                        renderInput={(params) => (
                            <InputText {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        height: 35,
                                        padding: 0,
                                        ...this.props.inputStyle,
                                        ...(this.isPopupOpen
                                            ? {
                                                borderBottom: 0
                                            }
                                            : {})
                                    },
                                    endAdornment: (
                                        <React.Fragment>
                                            {this.props.locationStates[this.props.inputKey]
                                                && this.props.locationStates[this.props.inputKey].isLoading
                                                ? <CircularProgress color="inherit" size={20} />
                                                : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                        getOptionLabel={(option) => option ? getLocationName(option) : "?"}
                        popupIcon={null}
                        getOptionSelected={(option: Api.LocationModel, value: Api.LocationModel) => option
                            ? option.locationId === value.locationId
                            : false}
                        PaperComponent={(props) => <Paper {...props}
                            className="location-items"
                            style={{
                                borderLeft: `1px solid ${Colors.baseColors.darkGray}`,
                                borderRight: `1px solid ${Colors.baseColors.darkGray}`,
                                borderBottom: `1px solid ${Colors.baseColors.darkGray}`,
                                borderBottomLeftRadius: 8,
                                borderBottomRightRadius: 8,
                                margin: 0,
                                padding: 0,
                                maxHeight: 300,
                                overflowY: 'auto',
                            }}>
                        </Paper>}
                        ListboxComponent={(props) => <List {...props}
                            style={{ ...props.style, overflow: "none", maxHeight: "none" }}>
                        </List>}
                        renderOption={(option: Api.LocationModel, state) =>
                            <div style={{
                                ...(state.selected
                                    ? { color: Colors.baseColors.lightBlue, fontWeight: 700 }
                                    : {}),
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                fontSize: 14
                            }}>
                                <img style={{ flex: "0 0 auto", paddingRight: 3 }} src={this.getIcon(option)} height={30}></img>
                                <div style={{ flex: 1, fontFamily: "Calibri" }}>{getLocationName(option)}</div>
                            </div>
                        }
                        onChange={(e, option) => this.handleChange(option as any)} />
                </div>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    locationStates: state.location,
    countries: state.seed.countries,
} as any), LocationStore.actionCreators)(LocationSelect) as any as React.ComponentClass<LocationSelectExternalProps>;