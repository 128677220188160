import * as React from 'react';
import * as Api from '../api/api';
import DialogModel from './DialogModal';
import GoogleMapReact from 'google-map-react';
import { getLocationName } from '../utils/utils';
import Button from "./Button";
import * as Colors from '../styles/colors';
import { Room } from "@material-ui/icons";
import InputText from "./InputText";
import * as _ from 'lodash';
import CenteredContentTabs from './CenteredContentTabs';
import * as TabsStore from "../store/Tabs";
import { getText } from '../utils/langTexts';
import LocationSelect from './LocationSelect';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';






interface LocationSelectSearchProps {
    locationDistances: Array<Api.LocationDistanceModel>;
    isLoading: boolean;
    requestTime: number;
    address: string;
    addressFound: string;
    isOpen: boolean;
    center: { lat: number; lng: number };
    selectedTab: string;
    selectLocation: (location: Api.LocationModel) => void;
    updateSearchAddress: (value: string) => void;
    requestSearchByAddress: (requestTime: number) => void;
    requestClose: () => void;
}

interface LocationSelectSearchState {
}

interface LocatedReactComponentProps {
    lat: number;
    lng: number;
    children: any;
}

//lng & lat will tell googlemap where to put it
class LocatedReactComponent extends React.Component<LocatedReactComponentProps, {}> {
    public render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

const defaultProps = {
    center: { lat: 48.8566, lng: 2.3522 },
    zoom: 6
};

const resultIndexs = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];


const numberStyle: React.CSSProperties = {
    width: 25,
    height: 25,
    border: "solid",
    borderWidth: 2,
    borderRadius: 50,
    borderColor: Colors.baseColors.lightBlue,
    fontSize: 17,
    fontWeight: "bold",
    alignItems: "center",
    marginRight: 20,
    marginLeft: 20,
    display: "flex",
    flexDirection: "row",
};

const numberStyleMap: React.CSSProperties = {
    width: 25,
    height: 25,
    border: "solid",
    borderWidth: 2,
    borderRadius: 50,
    fontSize: 17,
    fontWeight: "bold",
    alignItems: "center",
    marginRight: 20,
    marginLeft: 20,
    marginTop: 6,
    position: "relative",
    top: -25,
    left: -25,
};
const mainStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    flexGrow: 10,
    color: Colors.baseColors.lightBlue,
    height: "10%",
    minHeight: 40,
    minWidth: 470,
    textAlign: "center",
    alignItems: "center"
};

class LocationSelectSearch extends React.Component<LocationSelectSearchProps, LocationSelectSearchState> {

    constructor(props) {
        super(props);
    }

    handleAddressChanged(value: string) {
        this.props.updateSearchAddress(value);
    }


    get locationDistancesAll(): Array<Api.LocationDistanceModel> {
        let sorted = this.props.locationDistances
            ? _.sortBy(this.props.locationDistances, x => x.distance)
            : [];

        switch (this.props.selectedTab) {
            case "secondary":
                var x = sorted.filter(x => x.location.type == 'Output')
                return x;
            case "dry":
                var x = sorted.filter(x => x.location.type == 'Dry' )
                return x;
            case "main":
                return sorted.filter(x => x.location.type == 'Base')
            default:
                return sorted.filter(x => x.location.type == 'Base')
        }
    }

    public render() {

        return (
            <DialogModel
                maxWidth={"lg"}
                isOpen={this.props.isOpen}
                contentLabel="Location search"
                onRequestClose={() => this.props.requestClose()}
                contentStyle={{}}>
                <div style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "stretch"
                }}>
                    <div style={{
                        flex: "3 0 47%",
                        marginLeft: 20,
                        marginBottom: 20
                    }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: "AIzaSyDGDzZJrkhkSC89Kteuj4AVQBjgj9ocieM"
                            }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            center={this.props.center}>

                            {this.locationDistancesAll.map(ld =>
                                <LocatedReactComponent
                                    key={ld.location.locationId}
                                    lat={ld.location.latitude}
                                    lng={ld.location.longitude}>

                                    <div style={{
                                        ...numberStyleMap,
                                        ...(this.locationDistancesAll[this.locationDistancesAll.indexOf(ld)] &&
                                            this.locationDistancesAll[this.locationDistancesAll.indexOf(ld)].location.status === "Main"
                                            ? {
                                                backgroundColor: Colors.baseColors.lightBlue,
                                                borderColor: Colors.baseColors.lightBlue,
                                                color: "white",
                                            }
                                            : {
                                                backgroundColor: "white",
                                                borderColor: Colors.baseColors.lightBlue,
                                                color: Colors.baseColors.lightBlue,
                                            })
                                    }}>
                                        <div style={{ textAlign: "center", width: "100%" }}>
                                            {this.locationDistancesAll.indexOf(ld) + 1}
                                        </div>
                                    </div>
                                </LocatedReactComponent>
                            )}
                            {this.props.center && this.props.center.lat !== 0 && <LocatedReactComponent
                                lat={this.props.center.lat}
                                lng={this.props.center.lng}>
                                <Room style={{
                                    height: 35,
                                    width: 35,
                                    color: Colors.baseColors.lightBlue,
                                    fontSize: 25,
                                    backgroundColor: "white",
                                    borderRadius: 25,
                                    position: "relative",
                                    top: -35,
                                    left: -18,
                                }} />
                            </LocatedReactComponent>}
                        </GoogleMapReact>
                    </div>
                    <div style={{
                        flex: "1 0 45%",
                        flexGrow: 3,
                    }}>
                        <div style={{
                            marginLeft: 50,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "stretch"
                        }}>
                            <InputText
                                InputProps={{ style: { padding: "0px 0px", height: 35, borderRadius: "5px 0px 0px 5px" } }}
                                style={{
                                    flexGrow: 9,
                                }}
                                type="TextField"
                                placeholder="Enter an address..."
                                value={this.props.address}
                                onChange={(e) => this.handleAddressChanged(e.target.value)} />
                            <Button
                                style={{
                                    borderRadius: "0px 5px 5px 0px",
                                    border: 10,
                                    flex: 1,
                                    color: "white",
                                    marginLeft: 3,
                                    backgroundColor: Colors.baseColors.lightBlue
                                }}

                                onClick={(e) => {
                                    this.props.requestSearchByAddress(new Date().getTime());
                                    e.stopPropagation();
                                }}>Search</Button>
                        </div>
                        <div style={{
                            marginLeft: 50,
                            fontSize: 23,
                            marginTop: 10,
                            marginBottom: 10,
                            fontFamily: "Calibri",
                            fontWeight: "bold",
                            color: Colors.baseColors.darkGray
                        }}>
                            {this.props.addressFound}
                        </div>
                        <div style={{
                            display: "inline-grid",
                            backgroundColor: "white",
                            marginLeft: 10,
                            fontFamily: "Calibri",
                            fontSize: 17,
                            alignItems: "stretch",

                            color: Colors.baseColors.darkGray
                        }}>
                            <CenteredContentTabs
                                tabKey={"locationSelectSearch"}
                                data={[
                                    {
                                        title: "Base ports",
                                        tabKey: "main",
                                        tabContent: <div style={{ padding: 10 }}>
                                            <ul style={{
                                                listStyle: "none",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}>
                                                {resultIndexs.map(i =>
                                                    <li key={i} style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center"
                                                    }}>
                                                        <div className={i % 2 === 0 ? "port-search-result" : "port-search-result-alt"}
                                                            style={{ borderRadius: 5, cursor: "pointer" }}
                                                            onClick={(e) => { this.props.selectLocation(this.locationDistancesAll[i].location); this.props.requestClose() }}>
                                                            <div style={
                                                                this.locationDistancesAll[i] &&
                                                                    this.locationDistancesAll[i].location.status === "Main" ? mainStyle : {
                                                                    listStyle: "none",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    fontWeight: "bold",
                                                                    flexGrow: 10,
                                                                    height: "10%",
                                                                    minHeight: 40,
                                                                    minWidth: 470,
                                                                    textAlign: "center",
                                                                    alignItems: "center"
                                                                }}>
                                                                {this.locationDistancesAll.indexOf(this.locationDistancesAll[i]) > -1 && <div style={{
                                                                    ...numberStyle,
                                                                    ...(this.locationDistancesAll[i] &&
                                                                        this.locationDistancesAll[i].location.status === "Main"
                                                                        ? {
                                                                            backgroundColor: Colors.baseColors.lightBlue,
                                                                            color: "white",
                                                                            borderColor: Colors.baseColors.lightBlue
                                                                        }
                                                                        : {
                                                                            backgroundColor: "white",
                                                                            color: Colors.baseColors.lightBlue,
                                                                            borderColor: Colors.baseColors.lightBlue
                                                                        })
                                                                }} >
                                                                    <div style={{
                                                                        width: "100%",
                                                                        textAlign: "center",
                                                                    }}>
                                                                        {this.locationDistancesAll.indexOf(this.locationDistancesAll[i]) + 1}
                                                                    </div>
                                                                </div>}
                                                                <div style={{
                                                                    textAlign: "center",
                                                                    width: "auto",
                                                                    minWidth: 52,
                                                                }}>
                                                                    {this.locationDistancesAll.length > i
                                                                        ? this.locationDistancesAll[i].distance + " km"
                                                                        : ""}
                                                                </div>
                                                                <div style={{
                                                                    marginLeft: 30,
                                                                    textAlign: "left",

                                                                    width: "auto",
                                                                    minWidth: 70,
                                                                }}>
                                                                    {this.locationDistancesAll.length > i
                                                                        ? getLocationName(this.locationDistancesAll[i].location)
                                                                        : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    },
                                    {
                                        title: "Outports",
                                        tabKey: "secondary",
                                        tabContent: <div style={{ padding: 10 }}>
                                            <ul style={{
                                                listStyle: "none",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}>
                                                {resultIndexs.map(i =>
                                                    <li key={i} style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center"
                                                    }}>
                                                        <div className={i % 2 === 0 ? "port-search-result" : "port-search-result-alt"}
                                                            style={{ borderRadius: 5, cursor: "pointer" }}
                                                            onClick={(e) => { this.props.selectLocation(this.locationDistancesAll[i].location); this.props.requestClose() }}>
                                                            <div style={
                                                                this.locationDistancesAll[i] &&
                                                                    this.locationDistancesAll[i].location.status === "Main" ? mainStyle : {
                                                                    listStyle: "none",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    fontWeight: "bold",
                                                                    flexGrow: 10,
                                                                    height: "10%",
                                                                    minHeight: 40,
                                                                    minWidth: 470,
                                                                    textAlign: "center",
                                                                    alignItems: "center"
                                                                }}>
                                                                {this.locationDistancesAll.indexOf(this.locationDistancesAll[i]) > -1 && <div style={{
                                                                    ...numberStyle,
                                                                    ...(this.locationDistancesAll[i] &&
                                                                        this.locationDistancesAll[i].location.status === "Main"
                                                                        ? {
                                                                            backgroundColor: Colors.baseColors.lightBlue,
                                                                            color: "white",
                                                                            borderColor: Colors.baseColors.lightBlue
                                                                        }
                                                                        : {
                                                                            backgroundColor: "white",
                                                                            color: Colors.baseColors.lightBlue,
                                                                            borderColor: Colors.baseColors.lightBlue
                                                                        })
                                                                }} >
                                                                    <div style={{
                                                                        width: "100%",
                                                                        textAlign: "center",
                                                                    }}>
                                                                        {this.locationDistancesAll.indexOf(this.locationDistancesAll[i]) + 1}
                                                                    </div>
                                                                </div>}
                                                                <div style={{
                                                                    textAlign: "center",
                                                                    width: "auto",
                                                                    minWidth: 52,
                                                                }}>
                                                                    {this.locationDistancesAll.length > i
                                                                        ? this.locationDistancesAll[i].distance + " km"
                                                                        : ""}
                                                                </div>
                                                                <div style={{
                                                                    marginLeft: 30,
                                                                    textAlign: "left",

                                                                    width: "auto",
                                                                    minWidth: 70,
                                                                }}>
                                                                    {this.locationDistancesAll.length > i
                                                                        ? getLocationName(this.locationDistancesAll[i].location)
                                                                        : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    },
                                    {
                                        title: "Dry ports",
                                        tabKey: "dry",
                                        tabContent: <div style={{ padding: 10 }}>
                                            <ul style={{
                                                listStyle: "none",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}>
                                                {resultIndexs.map(i =>
                                                    <li key={i} style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center"
                                                    }}>
                                                        <div className={i % 2 === 0 ? "port-search-result" : "port-search-result-alt"}
                                                            style={{ borderRadius: 5, cursor: "pointer" }}
                                                            onClick={(e) => { this.props.selectLocation(this.locationDistancesAll[i].location); this.props.requestClose() }}>
                                                            <div style={
                                                                this.locationDistancesAll[i] &&
                                                                    this.locationDistancesAll[i].location.status === "Main" ? mainStyle : {
                                                                    listStyle: "none",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    fontWeight: "bold",
                                                                    flexGrow: 10,
                                                                    height: "10%",
                                                                    minHeight: 40,
                                                                    minWidth: 470,
                                                                    textAlign: "center",
                                                                    alignItems: "center"
                                                                }}>
                                                                {this.locationDistancesAll.indexOf(this.locationDistancesAll[i]) > -1 && <div style={{
                                                                    ...numberStyle,
                                                                    ...(this.locationDistancesAll[i] &&
                                                                        this.locationDistancesAll[i].location.status === "Main"
                                                                        ? {
                                                                            backgroundColor: Colors.baseColors.lightBlue,
                                                                            color: "white",
                                                                            borderColor: Colors.baseColors.lightBlue
                                                                        }
                                                                        : {
                                                                            backgroundColor: "white",
                                                                            color: Colors.baseColors.lightBlue,
                                                                            borderColor: Colors.baseColors.lightBlue
                                                                        })
                                                                }} >
                                                                    <div style={{
                                                                        width: "100%",
                                                                        textAlign: "center",
                                                                    }}>
                                                                        {this.locationDistancesAll.indexOf(this.locationDistancesAll[i]) + 1}
                                                                    </div>
                                                                </div>
                                                                }
                                                                <div style={{
                                                                    textAlign: "center",
                                                                    width: "auto",
                                                                    minWidth: 52,
                                                                }}>
                                                                    {this.locationDistancesAll.length > i
                                                                        ? this.locationDistancesAll[i].distance + " km"
                                                                        : ""}
                                                                </div>
                                                                <div style={{
                                                                    marginLeft: 30,
                                                                    textAlign: "left",

                                                                    width: "auto",
                                                                    minWidth: 70,
                                                                }}>
                                                                    {this.locationDistancesAll.length > i
                                                                        ? getLocationName(this.locationDistancesAll[i].location)
                                                                        : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    }
                                ]} />



                        </div>
                    </div>
                </div>
            </DialogModel>
        );
    }

}

export default connect(
    (state: ApplicationState) => ({
        selectedTab: state.tabs["locationSelectSearch"] ? state.tabs["locationSelectSearch"].selectedTab : "main"
    } as any),
    {
    })(LocationSelectSearch) as any